import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import GrowLogIcon from "../../assets/images/grow-log-icon.png";
import "../../assets/style/index.css";
import PlantIcon from "../../assets/images/plant1.png";
import TechIcon from "../../assets/images/technician(2)1.png";
import CallDateIcon from "../../assets/images/clendericon.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ReactPlayer from 'react-player'

const GrowTracking = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };
  const handlePrevDate = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });
  };

  const handleNextDate = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const getDatesForMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dates = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(year, month, i);
      dates.push({
        date: i,
        day: dayDate.toLocaleDateString("en-US", { weekday: "short" }),
      });
    }

    return dates;
  };

  const monthDates = getDatesForMonth(currentDate);

  return (
    <div className="py-4">
      <Container fluid>
        <Row>
          <Col lg={6}>
            <div
              className="grow-log-card position-relative mb-4"
              style={{
                backgroundImage: `url("./images/features/grow-card-log-bg1.png")`,
              }}
            >
              <div className="grow-log-content d-flex align-items-center gap-3 position-relative">
                <div className="grow-log-iocn">
                  <img
                    className="w-100 h-100"
                    src={GrowLogIcon}
                    alt="grow-log icon"
                  />
                </div>
                <h3 className="text-white">
                  Grow <span>Master</span> <br />
                  on demand
                </h3>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div
              className="grow-log-card position-relative mb-4"
              style={{
                backgroundImage: `url("./images/features/grow-log-card-2bg.png")`,
              }}
            >
              <div className="grow-log-content d-flex align-items-center gap-3 position-relative">
                <div className="grow-log-iocn">
                  <img
                    className="w-100 h-100"
                    src={GrowLogIcon}
                    alt="grow-log icon"
                  />
                </div>
                <h3 className="text-white">
                  Grow <span>Tech</span> <br />
                  on demand
                </h3>
              </div>
            </div>
          </Col>
        </Row>
        <form>
          <div className="select-subject list d-flex gap-4 flex-column py-4">
            <label
              htmlFor="Plant-Care"
              className="singleSubject d-flex justify-content-between align-items-center px-2 px-md-4 py-3 cursor-pointer rounded-3 col-12 col-md-8 col-lg-6 mx-auto"
            >
              <div className="list__item">
                <input
                  type="radio"
                  className="radio-btn"
                  name="choice"
                  id="Plant-Care"
                />
                <span className="label mb-0 fs-4 fw-500">Plant Care</span>
              </div>
              <div className="subject-icon">
                <img
                  className="w-100 h-auto"
                  src={PlantIcon}
                  alt="subject icon"
                />
              </div>
            </label>

            <label
              htmlFor="Tech-Care"
              className="singleSubject d-flex justify-content-between align-items-center px-2 px-md-4 py-3 cursor-pointer rounded-3 col-12 col-md-8 col-lg-6 mx-auto"
            >
              <div className="list__item">
                <input
                  type="radio"
                  className="radio-btn"
                  name="choice"
                  id="Tech-Care"
                />
                <span className="label mb-0 fs-4 fw-500">Tech Care</span>
              </div>
              <div className="subject-icon">
                <img
                  className="w-100 h-auto"
                  src={TechIcon}
                  alt="subject icon"
                />
              </div>
            </label>

            <label
              htmlFor="Scheduling"
              className="singleSubject d-flex justify-content-between align-items-center px-2 px-md-4 py-3 cursor-pointer rounded-3 col-12 col-md-8 col-lg-6 mx-auto"
            >
              <div className="list__item">
                <input
                  type="radio"
                  className="radio-btn"
                  name="choice"
                  id="Scheduling"
                />
                <span className="label mb-0 fs-4 fw-500">
                  Scheduling for Plants & Tech
                </span>
              </div>
              <div className="subject-icon">
                <img
                  className="w-100 h-auto"
                  src={CallDateIcon}
                  alt="subject icon"
                />
              </div>
            </label>
          </div>

          <div className="singleSubject selected-date-sec px-2 px-md-4 py-3 cursor-pointer rounded-3 col-12 col-md-8 col-lg-6 mx-auto">
            <div className="d-flex gap-4 justify-content-center align-items-center">
              <p className="mb-0 mb-0 fs-4 fw-500">{formatDate(currentDate)}</p>
              <div className="d-flex flex-column gap-3">
                <button
                  type="button"
                  className="border-0 bg-transparent prev-date"
                  onClick={handlePrevDate}
                >
                  <svg
                    width="34"
                    height="20"
                    viewBox="0 0 44 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.65234 26.2783L21.6115 1.99987L41.5707 26.2783"
                      stroke="black"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  className="border-0 bg-transparent next-date"
                  onClick={handleNextDate}
                >
                  <svg
                    width="34"
                    height="20"
                    viewBox="0 0 44 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.77734 2.03357L21.7365 26.312L41.6957 2.03357"
                      stroke="black"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
      <div className="selected-year-dates my-5 pt-4">
        <div className="dates-main">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            slidesPerView={7}
            spaceBetween={90}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 2, 
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 3, 
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 6, 
                spaceBetween: 50,
              },
              1440: {
                slidesPerView: 7, 
                spaceBetween: 90,
              },
            }}
          >
            {monthDates.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`singleDate cursor-pointer d-flex justify-content-center align-items-center flex-column ${selectedDate === item.date ? "selected-date" : ""
                    }`}
                  onClick={() => handleDateClick(item.date)}
                >
                  <p className="mb-0 fs-4 fw-500 text-center">{item.date}</p>
                  <p className="mb-0 fs-4 fw-500 text-center">{item.day}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Container className="py-5">
      <ReactPlayer
        url="https://youtu.be/PmfyAVCsptA"
        playing={isPlaying}
        controls={true}
        width="100%"
        height="450px"
      />
      </Container>

    </div>
  );
};

export default GrowTracking;
