import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Select from "react-select";

const GrowDoc = () => {
  const fileInputRef = useRef(null);
  const [imageSrcs, setImageSrcs] = useState([]); 
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOrgans, setSelectedOrgans] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
      "image/svg+xml",
    ];

    const invalidFiles = files.filter(
      (file) => !allowedTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      toast.error(
        "Please select valid image files (jpeg, png, jpg, gif, svg)."
      );
      return;
    }

    if (files.length > 0) {
      setSelectedFiles(files);

      // Preview the selected images
      const fileReaders = files.map((file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders).then((previews) => {
        setImageSrcs(previews); // Update image previews
      });
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const growerUser = JSON.parse(localStorage.getItem("grower_user"));
      const token = growerUser?.token;
  
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/growx/projects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        // Access the nested data array
        const projectsData = response.data.data?.data;
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          console.error("Unexpected response format:", response.data);
          toast.error("Failed to load projects.");
        }
      } catch (err) {
        console.error("Error fetching projects:", err);
        toast.error("Error fetching projects.");
      }
    };
  
    fetchProjects();
  }, []);

  
  const projectOptions = Array.isArray(projects)
  ? projects.map((project) => ({
      value: project.id,
      label: project.project_title,
    }))
  : [];
  
  const organOptions = [
    { value: "flower", label: "Flower" },
    { value: "leaf", label: "Leaf" },
    { value: "stem", label: "Stem" },
  ];

  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
  };

  const handleOrganChange = (selectedOptions) => {
    setSelectedOrgans(selectedOptions || []);
  };

  const handleDiagnoseClick = async () => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;

    if (!selectedFiles || selectedFiles.length === 0) {
      toast.error("Please select a file to upload.");
      return;
    }

    if (!selectedProject) {
      toast.error("Please select a project.");
      return;
    }

    if (selectedFiles.length !== selectedOrgans.length) {
      toast.error("The number of images and organs must be equal.");
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("images[]", file); // Append all selected images
    });

    selectedOrgans.forEach((organ) => {
      formData.append("organs[]", organ.value); // Append selected organs
    });

    formData.append("project", selectedProject.label);
    formData.append("project_id", selectedProject.value);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/growx/plant-net/diagnose`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("File uploaded successfully!");
      } else {
        toast.error("Failed to upload the file.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error uploading file. Please try again.";
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <div className="start-growing pb-4">
        <div className="selected-feature-section">
          <div className="section-heading container">
            <h2 className="text-white">Grow Doc</h2>
            <p className="text-white text-center fs-5 mb-5">
              Choose the plan that’s tailored to your needs and start
              experiencing the full benefits today. Whether you're just getting
              started or looking to upgrade, we have options designed for every
              level. Select a plan to unlock exclusive features and take your
              experience to the next level!
            </p>
          </div>

          <div className="container">
            <div className="growDoc-main bg-white rounded-3 p-3 p-md-5">
              <div className="col-12 col-lg-5 mx-auto">
                <label htmlFor="project" className="mb-2 fs-5">
                  Please Select Project
                </label>
                <Select
                  options={projectOptions}
                  onChange={handleProjectChange}
                  value={selectedProject}
                />
              </div>
              <div className="col-12 col-lg-5 mx-auto mt-4">
                <label htmlFor="organs" className="mb-2 fs-5">
                  Please Select Organs
                </label>
                <Select
                  options={organOptions}
                  isMulti
                  onChange={handleOrganChange}
                  value={selectedOrgans}
                />
              </div>
              <div className="doc-sec d-flex justify-content-center flex-wrap mt-4">
                <div className="col-12 col-lg-5 d-flex flex-column gap-4 justify-content-center align-item-center mx-auto">
                  {imageSrcs.length > 0 && (
                    <div className="uploadImage mx-auto rounded-4 overflow-hidden">
                      {imageSrcs.map((src, index) => (
                        <img
                          key={index}
                          className="w-100 h-100"
                          src={src}
                          alt={`uploaded-image-${index}`}
                        />
                      ))}
                    </div>
                  )}
                  <button
                    className="doc-upload-btn py-2"
                    onClick={handleUploadClick}
                  >
                    Upload Pictures
                    <span className="ms-3">
                      <svg
                        width="25"
                        height="30"
                        viewBox="0 0 45 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4983 37.7375V21.6553M22.4983 21.6553L28.7525 27.9095M22.4983 21.6553L16.2441 27.9095"
                          stroke="#00898D"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M36.7911 29.8888C39.4602 28.8431 42.1518 26.4608 42.1518 21.656C42.1518 14.5084 36.1955 12.7215 33.2173 12.7215C33.2173 9.14764 33.2173 2 22.4958 2C11.7744 2 11.7744 9.14764 11.7744 12.7215C8.7962 12.7215 2.83984 14.5084 2.83984 21.656C2.83984 26.4608 5.53143 28.8431 8.20057 29.8888"
                          stroke="#00898D"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple
                  />
                  <button
                    className="py-2 save-btn"
                    onClick={handleDiagnoseClick}
                    disabled={!selectedProject || !selectedOrgans.length}
                  >
                    Diagnose Plant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowDoc;
