import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HgpTermsConditionsContent from "../HgpTermsConditionsContent/HgpTermsConditionsContent";
import LearnMoreContent from "../LearnMoreContent/LearnMoreContent";
import TermsConditionsContent from "../TermsConditionsContent/TermsConditionsContent";
import RegisterForm from "../register/RegisterForm";
import { IoMdNotifications } from "react-icons/io";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./header.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import Loader from "../../components/loader";
import { useNotifications } from "../../utils/NotificationsContext";
import { FaUser } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { IoLogOut } from "react-icons/io5";

function Header() {
  const { unreadCount } = useNotifications();
  const { unreadNotifications, markNotificationAsRead } = useNotifications();
  const { notifications } = useNotifications();
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [showLearnMoreModal, setLearnMoreModalShow] = useState(false);
  const [showTermsModal, setTermModalShow] = useState(false);
  const [showHgpTermsModal, setHgpTermModalShow] = useState(false);
  const [goBackRoute, setGoBackRoute] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("grower_user");
    setIsLoggedIn(!!user);
  }, [location]);

  useEffect(() => {
    if (showRegisterModal) {
      handleClose();
    }
  }, [location]);

  const handleClose = () => {
    setGoBackRoute("");
    setRegisterModalShow(false);
  };
  const handleShow = () => setRegisterModalShow(true);

  const handleLearnMoreClose = () => {
    setGoBackRoute("");
    setLearnMoreModalShow(false);
  };
  const handleLearnMoreShow = () => setLearnMoreModalShow(true);

  const handleTermsClose = () => {
    setGoBackRoute("");
    setTermModalShow(false);
  };
  const handleTermsShow = () => setTermModalShow(true);

  const handleHgpTermsClose = () => {
    setGoBackRoute("");
    setHgpTermModalShow(false);
  };
  const handleHgpTermsShow = () => setHgpTermModalShow(true);

  const openRegisterModal = () => {
    handleLearnMoreClose();
    handleHgpTermsClose();
    handleTermsClose();
    handleShow();
  };

  const openTermConditionModal = () => {
    handleLearnMoreClose();
    handleTermsShow();
  };

  const openHgpTermConditionModal = () => {
    handleLearnMoreClose();
    handleHgpTermsShow();
  };

  const signOutNow = () => {
    localStorage.removeItem("grower_user");
    localStorage.removeItem("GrowFormAdded");
    localStorage.removeItem("grower_login");

    setIsLoggedIn(false);
  };

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  return (
    <>
      <Navbar expanded={expanded} expand="lg" className="navbar-dark">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/logos/growxAI-color.svg"
                width="160"
                height="54"
                className="d-inline-block align-top"
                alt="GrowX"
              />
            </Link>
          </Navbar.Brand>
          <div className="d-flex gap-1 gap-md-3">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            {/* {isLoggedIn && (
              <div className="d-block d-lg-none">
                <Dropdown
                  autoClose="inside"
                  className="header-notification-link"
                >
                  <Dropdown.Toggle className="toggle bg-transparent position-relative">
                    <IoMdNotifications className="fs-3 noti-bill" />

                    {notifications.filter(
                      (notification) => notification.status === "0"
                    ).length > 0 && (
                      <span className="notification-badge">
                        {unreadNotifications.length}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="px-3 py-4 right-0 notification-model-main">
                    <div className="d-flex justify-content-between">
                      <p className="notification-count">
                        Notifications ( {unreadNotifications.length}
                        unread)
                      </p>
                    </div>
                    {notifications.length === 0 ? (
                      <div className="loader-wrapper py-5 my-5 mx-auto w-100 d-flex justify-content-center align-items-center">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div className="notification-main-header pe-2">
                          {unreadNotifications
                            .filter(
                              (notification) => notification.status === "0"
                            )
                            .slice(0, 7)
                            .map((notification, index) => (
                              <div
                                key={notification.id}
                                className="py-2 px-4 rounded-2 mb-1"
                                onClick={() =>
                                  markNotificationAsRead(notification.id)
                                }
                                style={{
                                  backgroundColor:
                                    notification.status === "1"
                                      ? "#ffffff"
                                      : "#f0f0f0",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="header-notification-content">
                                  <p className="mb-0 fw-bold">
                                    {notification.title || "New Notification"}
                                  </p>
                                  <p className="mb-0">{notification.content}</p>
                                </div>
                              </div>
                            ))}
                        </div>
                        {notifications.filter(
                          (notification) => notification.status === "0"
                        ).length > 0 && (
                          <Dropdown.Item>
                            <Nav.Link
                              as={Link}
                              to="/notifications"
                              className="view-all-notifications mt-4"
                            >
                              View All Notifications
                            </Nav.Link>
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )} */}
          </div>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                as={Link}
                className={location.pathname === "/" ? "active-nav" : ""}
                to="/"
                onClick={handleNavItemClick}
              >
                Home
              </Nav.Link>
              {isLoggedIn && (
                <Nav.Link
                  as={Link}
                  className={
                    location.pathname === "/start-growing" ? "active-nav" : ""
                  }
                  to="/start-growing"
                  onClick={handleNavItemClick}
                >
                  Start Growing
                </Nav.Link>
              )}
              <Nav.Link
                as={Link}
                className={location.pathname === "/about" ? "active-nav" : ""}
                to="/about"
                onClick={handleNavItemClick}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={
                  location.pathname === "/tutorials" ? "active-nav" : ""
                }
                to="/tutorials"
                onClick={handleNavItemClick}
              >
                Tutorial
              </Nav.Link>
                <p className="mb-0 d-block d-lg-none">
              <Nav.Link
                as={Link}
                className={
                  location.pathname === "/notifications" ? "active-nav" : ""
                }
                to="/notifications"
                onClick={handleNavItemClick}
              >
                <p className="mb-0 d-flex">
                  Notifications
                  <p className="mb-0">
                    ( 
                    {notifications.filter(
                      (notification) => notification.status === "0"
                    ).length > 0 && (
                      <span className="">{unreadNotifications.length}</span>
                    )}
                     )
                  </p>
                </p>
              </Nav.Link>
              </p>
              <Nav.Link
                as={Link}
                className={location.pathname === "/pricing" ? "active-nav" : ""}
                to="/pricing"
                onClick={handleNavItemClick}
              >
                Pricing
              </Nav.Link>
              {!isLoggedIn ? (
                <Nav.Link onClick={handleShow}>Registration</Nav.Link>
              ) : (
                ""
              )}
            </Nav>

            {!isLoggedIn ? (
              <Nav.Link
                className="ms-lg-1 ms-0 mt-lg-0 mt-2"
                to="/login"
                as={Link}
              >
                <img
                  src="/images/avatar.png"
                  className="img-fluid pointer"
                  width={30}
                  alt="LOGGED"
                />
              </Nav.Link>
            ) : (
              <>
                {isLoggedIn && (
                  <Dropdown className="user-dropdown" autoClose="inside">
                    <Dropdown.Toggle
                      className="toggle"
                      id="dropdown-autoclose-inside"
                    >
                      <Nav.Link className="ms-lg-1 ms-0 mt-lg-0 mt-2">
                        <img
                          src="/images/avatar.png"
                          className="img-fluid pointer"
                          width={30}
                          alt="LOGGED"
                        />
                      </Nav.Link>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {isLoggedIn ? (
                        <>
                          <Dropdown.Item
                            className="px-5 text-black fw-600 py-2 d-flex align-items-center gap-2"
                            onClick={() => {
                              history("/profile");
                            }}
                          >
                            <FaUser />

                            <p className="mb-0">Profile</p>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={signOutNow}
                            className="px-5 text-black fw-600 py-2 d-flex align-items-center gap-2"
                          >
                            <IoLogOut />
                            <p className="mb-0">Sign Out</p>
                          </Dropdown.Item>
                        </>
                      ) : (
                        <div>
                          <Dropdown.Item
                            onClick={() => {
                              history("/login");
                            }}
                          >
                            Sign In
                          </Dropdown.Item>
                        </div>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            )}
          </Navbar.Collapse>
          {isLoggedIn && (
            <div className="d-none d-lg-block">
              <Dropdown autoClose="inside" className="header-notification-link">
                <Dropdown.Toggle className="toggle bg-transparent position-relative">
                  <IoMdNotifications className="fs-3 noti-bill" />

                  {notifications.filter(
                    (notification) => notification.status === "0"
                  ).length > 0 && (
                    <span className="notification-badge">
                      {unreadNotifications.length}
                    </span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className="px-3 py-4 right-0 notification-model-main ">
                  <div className="d-flex justify-content-between">
                    <p className="notification-count">
                      Notifications ( {unreadNotifications.length}
                      unread)
                    </p>
                  </div>
                  {notifications.length === 0 ? (
                    <div className="loader-wrapper py-5 my-5 mx-auto w-100 d-flex justify-content-center align-items-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div className="notification-main-header pe-2">
                        {unreadNotifications
                          .filter((notification) => notification.status === "0")
                          .slice(0, 7)
                          .map((notification, index) => (
                            <div
                              key={notification.id}
                              className="py-2 px-4 rounded-2 mb-1"
                              onClick={() =>
                                markNotificationAsRead(notification.id)
                              }
                              style={{
                                backgroundColor:
                                  notification.status === "1"
                                    ? "#ffffff"
                                    : "#f0f0f0",
                                cursor: "pointer",
                              }}
                            >
                              <div className="header-notification-content">
                                <p className="mb-0 fw-bold">
                                  {notification.title || "New Notification"}
                                </p>
                                <p className="mb-0">{notification.content}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                      {notifications.filter(
                        (notification) => notification.status === "0"
                      ).length > 0 && (
                        <Dropdown.Item>
                          <Nav.Link
                            as={Link}
                            to="/notifications"
                            className="view-all-notifications mt-4"
                          >
                            View All Notifications
                          </Nav.Link>
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </Container>
      </Navbar>
      {/* Modal Components */}
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <RegisterForm handleClose={handleClose} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showLearnMoreModal}
        onHide={handleLearnMoreClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="learn-more-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleLearnMoreClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <LearnMoreContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
            openTermConditionModal={() => {
              openTermConditionModal();
              setGoBackRoute("true");
            }}
            openHgpTermConditionModal={() => {
              openHgpTermConditionModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showTermsModal} onHide={handleTermsClose} size="xl" centered>
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleTermsClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleTermsClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <TermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHgpTermsModal}
        onHide={handleHgpTermsClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleHgpTermsClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleHgpTermsClose}>
            <img src="./images/close.svg" alt="close" />
          </div>
          <HgpTermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
