import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Profile from "../Profile/Profile";
import RegisterForm from "../register/RegisterForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

function Login() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [roleSelected, setRoleSelected] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleClose = () => {
    setRegisterModalShow(false);
  };

  const handleShow = () => {
    navigate("/register");
  };

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    setRoleSelected(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    const payload = {
      email,
      password,
      app: "GROWX",
      role: selectedRole,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Login failed. Please check your credentials.");
      }
      const data = await response.json();
      if (response.status === 200) {
        localStorage.setItem("grower_user", JSON.stringify(data));
        toast.success(data.message);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        throw new Error(
          data.message || "Login failed. Please check your credentials."
        );
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    }
  };

  return (
    <div className="auth-wrapper">
      <ToastContainer position="top-right" autoClose={3000} />
      <div>
        <div className="login-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xl-6 m-auto align-self-center">
                <div className="login-form">
                  <div className="login-form-wrapper">
                    <div className="text-center">
                      <img
                        src="/favicon.ico"
                        width={120}
                        className="img-fluid mb-4"
                        alt="icon"
                      />
                    </div>
                    <div className="welcome-back">
                      <h1>Welcome Back!</h1>
                    </div>
                    <div className="get-started">
                      <h3>Login to get started</h3>
                    </div>
                    {!roleSelected ? (
                      <div className="tech-select-btn text-center pt-4 d-flex gap-2 justify-content-center flex-wrap">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm py-2 px-4"
                          onClick={() => handleRoleSelection("GROWER")}
                        >
                          Grower Login
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm py-2 px-4"
                          onClick={() => handleRoleSelection("GROW_TECH")}
                        >
                          GrowTech Login
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm py-2 px-4"
                          onClick={() => handleRoleSelection("GROW_MASTER")}
                        >
                          GrowMaster Login
                        </button>
                      </div>
                    ) : (
                      <form className="pt-3 pb-3" onSubmit={handleSubmit}>
                  
                        <div className="form-group mb-2">
                          <label>Email Address</label>
                          <input
                            type="email"
                            placeholder="Enter your Email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group position-relative">
                          <label>Password</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your Password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "65%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              color: "#00898d",
                            }}
                          >
                            {showPassword ? (
                              <FaEyeSlash className="fs-5" />
                            ) : (
                              <FaEye className="fs-5" />
                            )}
                          </span>
                        </div>
                        {error && <div className="text-danger">{error}</div>}
                        <div className="text-end forget-pass">
                          <a href="/forget-password">Forget Password</a>
                        </div>
                        <div className="text-center pt-4 tech-select-btn">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm py-2 px-4 text-transform-lowercase"
                          >
                           {selectedRole.replace("_", " ")} Login
                          </button>
                        </div>
                      </form>
                    )}
                    <div className="text-center">
                      <div className="dont-have-account text-center pt-4">
                        Don’t have an account?{" "}
                        <a className="pointer" onClick={handleShow}>
                          Get Registered
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
          <Modal.Body
            className="register-modal"
            style={{
              backgroundImage: `url("./images/bg-logo.svg")`,
            }}
          >
            <div className="close-modal" onClick={handleClose}>
              <img src="./images/close.svg" className="" alt="close" />
            </div>
            <RegisterForm
              closeRegisterModal={() => setRegisterModalShow(false)}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Login;
