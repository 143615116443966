import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "../../pages/start-growing/start-growing.css";
import TechProfile from "../../assets/images/techprofileicon.png";
import Tranigle from "../../assets/images/chat-triangle.png";
import SendIcon from "../../assets/images/sendicon.png";
import DelIcon from "../../assets/images/delete1.png";
import DotsIcon from "../../assets/images/dots1.png";
import NewsIcon from "../../assets/images/news1.png";
import HappinessIcon from "../../assets/images/happiness1.png";
import FileIcon from "../../assets/images/attachments1.png";
import CalenderIcon from "../../assets/images/whitecalneder.png";
import LiveMeeting from "../../assets/images/woman-with-headset-usinglaptop1.png"
import Cut from "../../assets/images/phone2.png"
import Mute from "../../assets/images/mute.png"
import VideoCamera from "../../assets/images/video-camera1.png"
import { BsChatLeftDots } from "react-icons/bs";
import { CiVideoOn } from "react-icons/ci";
import { FiVideo } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import UserMap from "../GoogleMap";
import MailComponent from "../mailComponent";
import MeetingMain from "../meetingMain";
import MeetingDate from "../MeetingDate";


const GrowMasterMain = () => {
  const [showModal, setShowModal] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const [showLiveModal, setShowLiveModal] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [userEmail, setUserEmail] = useState("");


  const handleProceed = (user) => {
    console.log("Proceeding with user:", user.email);
    setUserEmail(user.email); // Save email to state
    setShowFeatures(true);
  };


  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowMailModal = () => {
    setShowMailModal(true);
  };
  const handleCloseMailModal = () => {
    setShowMailModal(false);
  };

  const handleShowLiveModal = () => {
    setShowLiveModal(true);
  };
  const handleCloseLiveModal = () => {
    setShowLiveModal(false);
  };

  return (
    <div className="start-growing">
      <div className="selected-feature-section">
        <div className="section-heading container">
          <h2 className="text-white">Grow Master</h2>
          <p className="text-white text-center fs-5 mb-5">
            Choose the plan that’s tailored to your needs and start experiencing the full benefits today. Whether you're just getting started or looking to upgrade, we have options designed for every level. Select a plan to unlock exclusive features and take your experience to the next level!
          </p>
        </div>
        <div className="container pb-5 ">
          <UserMap onProceed={handleProceed} />
        </div>
        {showFeatures && (
          <Container>

            <Row>
              <Col lg={4}>
                <div className="growx-feature d-flex flex-column justify-content-center gap-2 cursor-pointer px-2" onClick={handleShowMailModal}>
                  <span>
                    <MdOutlineMail style={{ fontSize: "48px" }} />
                  </span>
                  <p className="mb-0 text-black fs-5 fw-500">Email Us</p>
                  <p className="text-center">
                    Reach out to us via email we typically respond within 1-2 business days 
                  </p>

                </div>
              </Col>
              <Col lg={4}>
                <div className="growx-feature gap-2 cursor-pointer d-flex flex-column justify-content-center px-2" onClick={handleShowLiveModal}>
                  <span>
                    <FiVideo style={{ fontSize: "48px" }} />
                  </span>
                  <p className="mb-0 text-black fs-5 fw-500">Schedule Meeting</p>
                  <p className="text-center">
                   Our team is online 24/7
                  </p>
                </div>
              </Col>
              <Col lg={4}>
                <div
                  className="growx-feature gap-2 cursor-pointer d-flex flex-column justify-content-center px-2"
                  onClick={handleShowModal}
                >
                  <span>
                    <BsChatLeftDots style={{ fontSize: "48px" }} />
                  </span>
                  <p className="mb-0 text-black fs-5 fw-500">Live Chat</p>
                  <p className="text-center">
                  Instant help at your fingertips available 24/7
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>

      {/* chat model   */}
      <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
        <Modal.Header className="border-0 d-flex justify-content-end pb-0">
          <button className="bg-transparent border-0" onClick={handleCloseModal}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="15" fill="#001C1F" />
              <path d="M22 8.35352L8 22.3535" stroke="white" stroke-linecap="round" />
              <path d="M8.64648 8L22.2653 22.371" stroke="white" stroke-linecap="round" />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="chat-main pt-5 pb-3 px-2 px-lg-5 rounded-3 d-flex flex-column justify-content-between gap-3">
            <div className="d-flex flex-column gap-5 gap-lg-3">
              <div className="tech-chat d-flex gap-3">
                <div className="chat-profile">
                  <img className="w-100 h-100" src={TechProfile} alt="tech profile" />
                </div>
                <div className="chat-text-box w-100 position-relative">
                  <div className="position-absolute">
                    <img src={Tranigle} alt="chat icon" />
                  </div>
                  <p className="mb-0 chat-msg py-3 px-3 rounded-3">Hi, how can i help you.</p>
                </div>
              </div>
              <div className="tech-chat d-flex gap-3 justify-content-end">
                <div className="chat-text-box w-100 position-relative d-flex justify-content-end">
                  <div className="position-absolute">
                    <img src={Tranigle} alt="chat icon" />
                  </div>
                  <p className="mb-0 chat-msg py-3 px-3 rounded-3">
                    "I need your help regarding my plants. I have 20 types of plants at home, and I want them to grow perfectly. What do I need to ensure their proper growth?
                  </p>
                </div>
                <div className="chat-profile">
                  <img className="w-100 h-100" src={TechProfile} alt="tech profile" />
                </div>
              </div>
              <div className="tech-chat d-flex gap-3">
                <div className="chat-profile">
                  <img className="w-100 h-100" src={TechProfile} alt="tech profile" />
                </div>
                <div className="chat-text-box w-100 position-relative">
                  <div className="position-absolute">
                    <img src={Tranigle} alt="chat icon" />
                  </div>
                  <p className="mb-0 chat-msg py-3 px-3 rounded-3">Okay, we will guide you, provide proper feeding instructions, and give you a detailed plan for each plant.</p>
                </div>
              </div>
              <div className="tech-chat d-flex gap-3 justify-content-end">
                <div className="chat-text-box w-100 position-relative d-flex justify-content-end">
                  <div className="position-absolute">
                    <img src={Tranigle} alt="chat icon" />
                  </div>
                  <p className="mb-0 chat-msg py-3 px-3 rounded-3">Okay Thanks</p>
                </div>
                <div className="chat-profile">
                  <img className="w-100 h-100" src={TechProfile} alt="tech profile" />
                </div>
              </div>
              <div className="tech-chat d-flex gap-3">
                <div className="chat-profile">
                  <img className="w-100 h-100" src={TechProfile} alt="tech profile" />
                </div>
                <div className="chat-text-box w-100 position-relative">
                  <div className="position-absolute">
                    <img src={Tranigle} alt="chat icon" />
                  </div>
                  <p className="mb-0 chat-msg py-3 px-3 rounded-3">You are welcome</p>
                </div>
              </div>
            </div>
            <form>
              <div className="message-send-sec d-flex align-items-center p-2">
                <input type="text" className="w-100 py-2 px-2 border-0 bg-transparent outline-none" />
                <button type="button" className="bg-black border-0 rounded-3 text-white p-2">
                  <img src={SendIcon} alt="send" />
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>

      </Modal>

      {/* Mail model   */}
      <Modal show={showMailModal} onHide={handleCloseMailModal} centered size="lg">
        <Modal.Header className="border-0 d-flex justify-content-end pb-0">
          <button className="bg-transparent border-0" onClick={handleCloseMailModal}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="15" fill="#001C1F" />
              <path d="M22 8.35352L8 22.3535" stroke="white" stroke-linecap="round" />
              <path d="M8.64648 8L22.2653 22.371" stroke="white" stroke-linecap="round" />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <MailComponent userEmail={userEmail} />
        </Modal.Body>
      </Modal>

      {/* Live meeting model   */}
      <Modal show={showLiveModal} onHide={handleCloseLiveModal} centered size="lg">
        <Modal.Header className="border-0 d-flex justify-content-end pb-0">
        </Modal.Header>
        <Modal.Body>
          <MeetingDate/>
          {/* <MeetingMain handleCloseLiveModal={handleCloseLiveModal} /> */}
        </Modal.Body>

      </Modal>
    </div>
  );
};

export default GrowMasterMain;
