import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import GrowLogIcon from "../../assets/images/grow-log-icon.png";
import "../../assets/style/index.css";
import '../../assets/style/radio.css'
import axios from "axios";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GrowDaily from "./growDaily";
import ProjectListing from "../projectsComponents/projectListing";
import Select from 'react-select';
const WeekSelectOption = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },


]

const DaysSelectOption = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
]

const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
    textAlign: 'center',

  }),
};

const GrowLog = () => {
  const [showForm, setShowForm] = useState(false);
  const [showProjectListing, setShowProjectListing] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showCompletedSection, setShowCompletedSection] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Not yet");
  const [projectName, setProjectName] = useState("");
  const totalSteps = 10;
  const [activeStep, setActiveStep] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [growthStage, setGrowthStage] = useState("None of the above");
  const [counts, setCounts] = useState({
    plants: 1,
    week: 1,
    day: 1,
  });

  const handleCreateProject = () => {
    setShowForm(true);
    setShowProjectListing(false);
  };

  const hanldeBack = () => {
    setShowForm(false);
  }

  useEffect(() => {
    const isGrowFormAdded = localStorage.getItem("GrowFormAdded") === "true";
    setShowCompletedSection(isGrowFormAdded);
  }, []);

  const handleApiSubmit = async () => {
    if (!currentQuestion) return;
    if (!selectedOptions[currentQuestionIndex]) {
      toast.error("Please select an option before proceeding.");
      return false;
    }
    const payload = {
      question_id: currentQuestion.id,
      answer: selectedOptions[currentQuestionIndex],
      is_first: currentQuestionIndex === 0,
      is_last_true: currentQuestionIndex === questions.length - 1,
      project_title: projectName || null,
      project_id: 1,
    };
    // Check if we are on question index 9
    if (currentQuestionIndex === 9) {
      if (selectedOption === "Yes") {
        // Include additional fields if the answer is "Yes"
        payload.no_of_plants = counts.plants;
        payload.current_week = counts.week;
        payload.current_day = counts.day;
        payload.stage = document.querySelector('.growth-select').value;
      } else {
        payload.no_of_plants = 1;
        payload.current_week = 1;
        payload.current_day = 1;
        payload.stage = "none of above";
      }
    }
    try {
      const growerUser = JSON.parse(localStorage.getItem("grower_user"));
      if (!growerUser?.token) {
        toast.error("No valid token found.");
        console.error("No valid token found");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/growx/submit-answer`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${growerUser.token}`,
          },
        }
      );

      if (response.data?.status) {
        toast.success("Step data submitted successfully!");
        console.log("Step data submitted successfully:", response.data);
        if (currentQuestionIndex === questions.length - 1) {
          localStorage.setItem("GrowFormAdded", "true");
          setShowCompletedSection(true);
          console.log("Form submission completed and saved to localStorage.");
        }
        return true;
      }
      else {
        toast.error(response.data?.message || "Unknown error occurred.");
        console.error("API Error:", response.data?.message || "Unknown error");
        return false;
      }
    } catch (error) {
      console.error("Error submitting step data:", error.message);
      if (error.response && error.response.status === 401) {
        toast.error(
          "Forbidden: You don't have access to this feature. Upgrade your subscription to proceed."
        );
      }
      return false;
    }
  };

  const handleIncrement = (field) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [field]: prevCounts[field] + 1,
    }));
  };

  const handleDecrement = (field) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [field]: prevCounts[field] > 0 ? prevCounts[field] - 1 : 0,
    }));
  };

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        if (!growerUser?.token) {
          console.error("No valid token found");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/growx/questions`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${growerUser.token}`,
            },
          }
        );

        if (response.data?.status) {
          setQuestions(response.data.data);

          // Initialize selectedOption from localStorage
          const savedOptions = JSON.parse(localStorage.getItem("selectedOptions")) || {};
          setSelectedOptions(savedOptions);

          if (savedOptions[0] !== undefined) {
            setSelectedOption(savedOptions[0]); // Set selectedOption for the first question
          }
        } else {
          console.error("API Error:", response.data?.message || "Unknown error");
        }
      } catch (error) {
        console.error("Error fetching questions:", error.message);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    const savedOptions = JSON.parse(localStorage.getItem("selectedOptions")) || {};
    setSelectedOptions(savedOptions);
  }, []);

  useEffect(() => {
    setActiveStep(currentQuestionIndex + 1);
  }, [currentQuestionIndex]);

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setSelectedOption(e.target.value);
    setSelectedOptions((prev) => {
      const updatedOptions = { ...prev, [currentQuestionIndex]: value };
      localStorage.setItem("selectedOptions", JSON.stringify(updatedOptions));
      return updatedOptions;
    });
    if (e.target.value !== "Let's Grow!!!") {
      setProjectName("");
    }
    if (e.target.value === "Let's Grow!!!") {
      setProjectName("");
    } else {
      setProjectName("");
    }
  };

  const handleNext = async () => {
    const isSuccessful = await handleApiSubmit();
    if (isSuccessful) {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prev) => prev + 1);
      }
    } else {
      console.log("Cannot proceed to the next step due to API error or skipped submission.");
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    await handleApiSubmit();
    setShowForm(false);
    localStorage.removeItem("selectedOptions");
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="py-4">
      <ToastContainer position="top-right" autoClose={3000} />
      <Container fluid>
        <Row>
          <Col lg={6}>
            <div className="grow-log-card position-relative mb-4"
              style={{
                backgroundImage: `url("./images/features/grow-card-log-bg1.png")`,
              }}
            >
              <div className="grow-log-content d-flex align-items-center gap-3 position-relative">
                <div className="grow-log-iocn">
                  <img
                    className="w-100 h-100"
                    src={GrowLogIcon}
                    alt="grow-log icon"
                  />
                </div>
                <h3 className="text-white">
                  Grow <span>Master</span> <br />
                  on demand
                </h3>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="grow-log-card position-relative mb-4"
              style={{
                backgroundImage: `url("./images/features/grow-log-card-2bg.png")`,
              }}
            >
              <div className="grow-log-content d-flex align-items-center gap-3 position-relative">
                <div className="grow-log-iocn">
                  <img
                    className="w-100 h-100"
                    src={GrowLogIcon}
                    alt="grow-log icon"
                  />
                </div>
                <h3 className="text-white">
                  Grow <span>Tech</span> <br />
                  on demand
                </h3>
              </div>
            </div>
          </Col>
        </Row>
        {!showForm && !showProjectListing && (
          <div className="container py-5 d-flex justify-content-center">
            <button className="create-project-btn" onClick={handleCreateProject}>
              Create New Project
            </button>
          </div>
        )}
        {!showForm && (
          <>
            <ProjectListing />
          </>
        )}

        {showForm && (
          <Col>
            <div className="py-5 container">
              <h3 className="text-white text-center">
                Lets us know
              </h3>
              <form className="multistep-form pt-4">
                <div className="form-steps-num d-flex justify-content-between align-items-center position-relative">
                  {steps.map((step) => (
                    <React.Fragment key={step}>
                      <div
                        className={`singleStep ${step === activeStep ? 'active-step' : ''}`}
                      >
                        {step}
                      </div>
                      {step !== steps.length && <div className="singleStepspacer"></div>}
                    </React.Fragment>
                  ))}
                </div>
                <div className="question-sec pt-4">
                  {currentQuestion ? (
                    <>
                      <h4 className="text-white">Question {currentQuestionIndex + 1} of {questions.length}</h4>
                      <div className="pt-4">
                        <p className="text-white fs-5">{currentQuestion.question}</p>
                        {currentQuestion.options.map((option, index) => (
                          <div className="text-white mb-2 radio" key={index}>
                            <input
                              type="radio"
                              id={`option-${index}`}
                              name="questionOption"
                              value={option}
                              onChange={handleOptionChange}
                              checked={selectedOptions[currentQuestionIndex] === option}
                            />
                            <label
                              htmlFor={`option-${index}`}
                              className="radio-label ps-2 fs-6"
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                      {currentQuestionIndex === 0 && selectedOption !== "Not yet" && (
                        <div className="pt-4">
                          <label htmlFor="projectName" className="text-white">
                            Project Name:
                          </label>
                          <input
                            type="text"
                            id="projectName"
                            className="form-control border-white border-1 text-white bg-transparent mt-2"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            placeholder="Enter your project name"
                          />
                        </div>
                      )}

                      {currentQuestionIndex === 9 && selectedOption === "Yes" && (
                        <div className="last-step-sec">
                          <div className="d-flex align-items-center gap-5 py-4">
                            {/* Plants Section */}
                            <div className="plant">
                              <label className="text-white fs-5">Plants</label>
                              <div className="d-flex align-items-center fs-5 text-black bg-white rounded-3 py-1">
                                <button
                                  type="button"
                                  className="h-100 px-2 border-0 bg-transparent"
                                  onClick={() => handleDecrement("plants")}
                                >
                                  <FaMinus />
                                </button>
                                <p className="mb-0 px-3 btn-data">{counts.plants}</p>
                                <button
                                  type="button"
                                  className="h-100 px-2 border-0 bg-transparent"
                                  onClick={() => handleIncrement("plants")}
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>

                            {/* Week Section */}
                            <div className="week">
                              <label className="text-white fs-5">Week</label>
                              <div className="d-flex align-items-center fs-5 text-black bg-white rounded-3 py-1">
                                <button
                                  type="button"
                                  className="h-100 px-2 border-0 bg-transparent"
                                  onClick={() => handleDecrement("week")}
                                >
                                  <FaMinus />
                                </button>
                                <p className="mb-0 px-3 btn-data">{counts.week}</p>
                                <button
                                  type="button"
                                  className="h-100 px-2 border-0 bg-transparent"
                                  onClick={() => handleIncrement("week")}
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>

                            {/* Day Section */}
                            <div className="day">
                              <label className="text-white fs-5">Day</label>
                              <div className="d-flex align-items-center fs-5 text-black bg-white rounded-3 py-1">
                                <button
                                  type="button"
                                  className="h-100 px-2 border-0 bg-transparent"
                                  onClick={() => handleDecrement("day")}
                                >
                                  <FaMinus />
                                </button>
                                <p className="mb-0 px-3 btn-data">{counts.day}</p>
                                <button
                                  type="button"
                                  className="h-100 px-2 border-0 bg-transparent"
                                  onClick={() => handleIncrement("day")}
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="grow-stage-select d-flex flex-column pb-4">
                            <label className="text-white fs-5">Growth Stage</label>
                            <select
                              className="growth-select py-2 px-3 rounded-3 fs-5"
                              onChange={(e) => setGrowthStage(e.target.value)} // Add this line
                            >
                              <option>None of the above</option>
                              <option>Germinating (1-7 days)</option>
                              <option>Vegetative: (2-8 weeks)</option>
                              <option>Flowering (6-8 weeks)</option>
                              <option>Harvesting</option>
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-start gap-4 pt-4">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handlePrevious}
                          disabled={currentQuestionIndex === 0}
                        >
                          Previous
                        </button>

                        {selectedOption == "Not yet" ? (
                          <button
                            type="button" onClick={hanldeBack}
                            className="btn btn-primary"
                          >
                            Close
                          </button>
                        ) :
                          <div>
                            {currentQuestionIndex === 9 ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            ) : <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleNext}

                            >
                              Next
                            </button>}
                          </div>
                        }
                      </div>
                    </>
                  ) : (
                    <p className="text-white">Loading questions...</p>
                  )}
                </div>
              </form>
            </div>
          </Col>
        )}

        {showProjectListing && <ProjectListing />}
      </Container>
    </div>
  );
};

export default GrowLog;
