import React, { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import "../project.css"
import Loader from '../../loader';
import "../../../assets/style/index.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

const WeekSelectOption = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
]

const DaysSelectOption = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
]

const customStyles = {
    placeholder: (provided) => ({
        ...provided,
        color: 'white',
        textAlign: 'center',
    }),
};

const ProjectListing = ({ onHide }) => {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    console.log(projects, "projects ");
    const [selectedProject, setSelectedProject] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDailyReportModal, setShowDailyReportModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 6;
    const [isGrowerUser, setIsGrowerUser] = useState(false);
    const [plantData, setPlantData] = useState(false);
    const [temperatureHigh, setTemperatureHigh] = useState("");
    const [temperatureLow, setTemperatureLow] = useState("");
    const [humidityHigh, setHumidityHigh] = useState("");
    const [humidityLow, setHumidityLow] = useState("");
    const [co2High, setCo2High] = useState("");
    const [co2Low, setCo2Low] = useState("");
    const [waterVolumeHigh, setWaterVolumeHigh] = useState("");
    const [waterVolumeLow, setWaterVolumeLow] = useState("");
    const [waterPhHigh, setWaterPhHigh] = useState("");
    const [waterPhLow, setWaterPhLow] = useState("");
    const [waterTempHigh, setWaterTempHigh] = useState("");
    const [waterTempLow, setWaterTempLow] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [loading, setLoading] = useState(true);
    const ProceedNext = () => {
        setPlantData(true);
    }

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            console.log("Selected file:", selectedFile);
            setFile(selectedFile);
        } else {
            console.log("No file selected.");
        }
    };

    const checkGrowerUser = () => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        setIsGrowerUser(!!growerUser);
    };

    useEffect(() => {
        checkGrowerUser();

        const interval = setInterval(() => {
            checkGrowerUser();
        }, 1000); // Check every second for real-time updates

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        const fetchProjects = async () => {
            const growerUser  = JSON.parse(localStorage.getItem("grower_user"));
            const token = growerUser ?.token;
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/growx/projects`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const fetchedProjects = Array.isArray(response.data.data.data) ? response.data.data.data : [];
                setProjects(fetchedProjects);
            } catch (err) {
                console.error('Error fetching projects:', err);
                setError('Failed to fetch projects');
            } finally {
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);

    const handleExport = async () => {
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        const token = growerUser?.token;

        if (!selectedProject || !selectedProject.id) {
            toast.error("No project selected for export.");
            return;
        }

        const payload = {
            ids: [selectedProject.id.toString()],
        };

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/growx/export-project-data`,
                {
                    params: payload,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success("Request Recieved for your data export. You will be notified once your download is ready as it takes a few minutes to prepare.");
                setShowModal(false);
            } else {
                toast.error("Failed to export project data.");
            }
        } catch (err) {
            console.error("Error exporting project data:", err);
            toast.error("An error occurred while exporting the project data.");
        }
    };
    ;
    const handleShowDailyReportModel = (project) => {
        setSelectedProject(project);
        setShowDailyReportModal(true);
    };
    const handleDailyReportCloseModal = () => {
        setSelectedProject(null);
        setShowDailyReportModal(false);
    };

    const handleShowDetails = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
        setShowModal(false);
    };

    const handleSeeMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    // const displayedProjects = projects.slice(0, currentPage * projectsPerPage);
    const displayedProjects = (projects || []).slice(0, currentPage * projectsPerPage);

    if (!isGrowerUser) {
        return <p className="text-center mb-5 fs-4">Please log in to view projects.</p>;
    }


    const handleSave = async () => {
        if (!selectedProject) {
            toast.error("No project selected.");
            return;
        }

        if (!selectedWeek || !selectedDay) {
            toast.error("Please select both week and day.");
            return;
        }

        const payload = {
            project_id: selectedProject.id,
            week_id: parseInt(selectedWeek, 10),
            day_id: parseInt(selectedDay, 10),
            description,
            daily_data: JSON.stringify(
                {
                    enviroment: {
                        temperature: {
                            high: parseFloat(temperatureHigh),
                            low: parseFloat(temperatureLow)
                        },
                        humidity: {
                            high: parseFloat(humidityHigh),
                            low: parseFloat(humidityLow)
                        },
                        co2: {
                            high: parseFloat(co2High),
                            low: parseFloat(co2Low)
                        },
                    },
                    feeding: {
                        water_volume: {
                            high: parseFloat(waterVolumeHigh),
                            low: parseFloat(waterVolumeLow)
                        },
                        water_ph: {
                            high: parseFloat(waterPhHigh),
                            low: parseFloat(waterPhLow)
                        },
                        water_temperature: {
                            high: parseFloat(waterTempHigh),
                            low: parseFloat(waterTempLow)
                        },
                    },
                },
            ),
            image: file,
        };

        const formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
            if (key === "image" && file) {
                formData.append(key, file);
            } else {
                formData.append(key, value);
            }
        });

        try {
            const growerUser = JSON.parse(localStorage.getItem("grower_user"));
            const token = growerUser?.token;

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/growx/submit-daily-data`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200 || 201) {
                setShowDailyReportModal(false);
                toast.success("Daily report submitted successfully!");
            } else {
                // toast.error("Failed to submit daily report.");
                setShowDailyReportModal(false);
            }
        } catch (error) {
            console.error("Error submitting daily report:", error);
            toast.error("An error occurred while submitting the daily report.");
        }
    };



    return (
        <>
            <ToastContainer />
            <div className='container mb-5 position-relative'>
                <div className='projects-cards pb-5'>
                    {loading ? (
                        <div className='d-flex justify-content-center align-items-center w-100 mx-auto position-absolute'>
                            <Loader />
                        </div>
                    ) : displayedProjects.length > 0 ? (
                        displayedProjects.map((project) => (
                            <div key={project.id} className='porject-card p-3 position-relative'>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <p className='plant-data'>{project.project_title}</p>
                                <p className='project-date'>{project.created_at}</p>
                            </div>
                            <div class="hover-card-box position-absolute w-100 h-100 top-0 bottom-0 d-flex flex-column justify-content-center align-items-center gap-3">
                                <button onClick={() => handleShowDailyReportModel(project)} class="bg-transparent show-details-btn">
                                    Add Daily Report
                                </button>
                                <button onClick={() => handleShowDetails(project)} class="bg-transparent show-details-btn">
                                    Show Details
                                </button>

                            </div>
                        </div>
                        ))
                    ) : (
                        <div className='d-flex text-white fs-4 justify-content-center align-items-center w-100 mx-auto position-absolute'>
                            <p>No data yet available</p>
                        </div>
                    )}
                </div>
                {projects.length > displayedProjects.length && (
                    <div className="text-center">
                        <button onClick={handleSeeMore} className="btn btn-primary px-4">See More</button>
                    </div>
                )}

                {/* Modal to show project details */}
                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProject?.project_title || 'Project Details'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedProject ? (
                            Array.isArray(selectedProject.project_daily_data) && selectedProject.project_daily_data.length > 0 ? (
                                <>
                                    <Accordion defaultActiveKey="0">
                                        {selectedProject.project_daily_data.map((dailyData, index) => (
                                            <Accordion.Item eventKey={index.toString()} key={index}>
                                                <Accordion.Header className='project-heading'>
                                                    Plant Data {dailyData.created_at || 'N/A'}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {dailyData.image_path && (
                                                        <img className='plant-image-data rounded-3 my-4 w-100'
                                                            src={dailyData.image_path}
                                                            alt={`Daily Data ${index + 1}`}

                                                        />
                                                    )}
                                                    {dailyData.daily_data && (
                                                        <div className='plant-data-sec accordian-table-data'>
                                                            <div className="table-responsive">
                                                                <table class="table text-white mt-2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text environ w-100">Environment</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">High</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">Low</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dailyData.daily_data.enviroment && (
                                                                            <tr>
                                                                                <th className="bg-transparent border-0 text-black fs-6 fw-500">Temperature</th>

                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.temperature.high || "N/A"}
                                                                                    </p>
                                                                                </td>


                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.temperature.low || "N/A"}
                                                                                    </p>
                                                                                </td>

                                                                            </tr>

                                                                        )}

                                                                        {dailyData.daily_data.enviroment && (
                                                                            <tr>
                                                                                <th className="bg-transparent border-0 text-black fs-6 fw-500">Humidity %</th>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.humidity.high || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.humidity.low || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {dailyData.daily_data.enviroment && (
                                                                            <tr>
                                                                                <th className="bg-transparent border-0 text-black fs-6 fw-500">CO2 PPM</th>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.co2.high || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.enviroment.co2.low || "N/A"}

                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-responsive">
                                                                <table class="table text-white mt-2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-5 w-100">Feeding</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">High</th>
                                                                            <th scope="col" className="bg-transparent border-0 greenish-text fs-6 fw-500 text-center">Low</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dailyData.daily_data.feeding && (
                                                                            <tr>
                                                                                <th className="bg-transparent border-0 text-black fs-6 fw-500">Water Volume</th>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.feeding.water_volume.high || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.feeding.water_volume.low || "N/A"}

                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {dailyData.daily_data.feeding && (
                                                                            <tr>
                                                                                <th className="bg-transparent border-0 text-black fs-6 fw-500">Water pH</th>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.feeding.water_ph.high || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.feeding.water_ph.low || "N/A"}

                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        {dailyData.daily_data.feeding && (
                                                                            <tr>
                                                                                <th className="bg-transparent border-0 text-black fs-6 fs-5 fw-500">Water Temp</th>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.feeding.water_temperature.high || "N/A"}
                                                                                    </p>
                                                                                </td>
                                                                                <td className="bg-transparent border-0 text-black">
                                                                                    <p className='mb-0 border rounded-2 px-1 py-1 text-center border-1 border-black'>
                                                                                        {dailyData.daily_data.feeding.water_temperature.low || "N/A"}

                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className='notes'>
                                                                <p className='fs-5 greenish-text fw-600 mb-0'>Notes</p>
                                                                <p> {dailyData.description || "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                    <div className='mt-4 d-flex justify-content-center'>
                                        <button onClick={handleExport} className='py-2 px-4 rounded-3 export-btn'>
                                            Export
                                        </button>
                                    </div>
                                </>
                            ) : (

                                <p className='text-center'>No Project Detail Found</p>
                            )
                        ) : (
                            <p>Loading project details...</p>
                        )}

                    </Modal.Body>
                </Modal>


                {/* daily report model  */}
                <Modal className="daiy-report-model" show={showDailyReportModal} onHide={handleDailyReportCloseModal} size="lg" centered>
                    <Modal.Header className="text-white d-flex justify-content-between" >
                        <Modal.Title className="text-white">{selectedProject?.project_title || 'Project Details'}</Modal.Title>
                        <button onClick={handleDailyReportCloseModal} className='text-white bg-transparent border-0 fs-1'>
                            <IoClose className='text-white' />
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ backgrounColor: "#001c1f" }}>
                        <div className="plant-data-sec container grow-log-options-sec">
                            <h3 className="text-white text-center fs-1 fw-bolder py-3">Add Plant Data</h3>

                            <div className=" d-flex flex-wrap mt-md-5 mt-lg-0 bg-white p-4 rounded-4">
                                <div className="select-week col-12 col-md-6 pe-md-3 mb-4 mb-lg-0">
                                    <h3 className="text-center mb-3">Select Week</h3>
                                    <div className="option-box p-5">
                                        <p className="text-white text-center fs-2 mb-0">Week</p>
                                        <Select options={WeekSelectOption}
                                            placeholder="Select a week"
                                            styles={customStyles}
                                            onChange={(option) => setSelectedWeek(option.value)}
                                        />
                                    </div>
                                </div>
                                <div className="select-days col-12 col-md-6 ps-md-3 mb-4 mb-lg-0">
                                    <h3 className="text-center mb-3">Select Day</h3>
                                    <div className="option-box p-5">
                                        <p className="text-white text-center fs-2 mb-0">Day</p>
                                        <Select options={DaysSelectOption}
                                            placeholder="Select a Day"
                                            styles={customStyles}
                                            onChange={(option) => setSelectedDay(option.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table class="table text-white mt-5">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-transparent border-0 text-white fs-3 w-100">Environment</th>
                                            <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">High</th>
                                            <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">Low</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th className="bg-transparent border-0 text-white fs-5 fw-500">Temperature</th>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={temperatureHigh} onChange={(e) => setTemperatureHigh(e.target.value)} />
                                            </td>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={temperatureLow} onChange={(e) => setTemperatureLow(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="bg-transparent border-0 text-white fs-5 fw-500">Humidity %</th>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={humidityHigh} onChange={(e) => setHumidityHigh(e.target.value)} />
                                            </td>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={humidityLow} onChange={(e) => setHumidityLow(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="bg-transparent border-0 text-white fs-5 fw-500">CO2 PPM</th>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={co2High} onChange={(e) => setCo2High(e.target.value)} />
                                            </td>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={co2Low} onChange={(e) => setCo2Low(e.target.value)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive">
                                <table class="table text-white mt-4">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="bg-transparent border-0 text-white fs-3 w-100">Feeding</th>
                                            <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">High</th>
                                            <th scope="col" className="bg-transparent border-0 text-white fs-3 fw-500 text-center">Low</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th className="bg-transparent border-0 text-white fs-5 fw-500">Water  volume</th>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={waterVolumeHigh} onChange={(e) => setWaterVolumeHigh(e.target.value)} />
                                            </td>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={waterVolumeLow} onChange={(e) => setWaterVolumeLow(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="bg-transparent border-0 text-white fs-5 fw-500">Water pH</th>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={waterPhHigh} onChange={(e) => setWaterPhHigh(e.target.value)} />
                                            </td>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={waterPhLow} onChange={(e) => setWaterPhLow(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="bg-transparent border-0 text-white fs-5 fw-500">Water Temp</th>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={waterTempHigh} onChange={(e) => setWaterTempHigh(e.target.value)} />
                                            </td>
                                            <td className="bg-transparent border-0 text-white">
                                                <input type="number" value={waterTempLow} onChange={(e) => setWaterTempLow(e.target.value)} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-4">
                                <textarea
                                    className="w-100 p-3 fs-5 rounded-4 text-black"
                                    placeholder="Add description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <div className="doc-sec d-flex justify-content-center flex-wrap mt-4">
                                <div className=" d-flex flex-column gap-4 justify-content-center align-item-center">
                                    <button
                                        className="doc-upload-btn py-2 px-3"
                                        onClick={handleUploadClick}
                                    >
                                        Upload Picture
                                        <span className="ms-3">
                                            <svg
                                                width="25"
                                                height="30"
                                                viewBox="0 0 45 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M22.4983 37.7375V21.6553M22.4983 21.6553L28.7525 27.9095M22.4983 21.6553L16.2441 27.9095"
                                                    stroke="#00898D"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M36.7911 29.8888C39.4602 28.8431 42.1518 26.4608 42.1518 21.656C42.1518 14.5084 36.1955 12.7215 33.2173 12.7215C33.2173 9.14764 33.2173 2 22.4958 2C11.7744 2 11.7744 9.14764 11.7744 12.7215C8.7962 12.7215 2.83984 14.5084 2.83984 21.656C2.83984 26.4608 5.53143 28.8431 8.20057 29.8888"
                                                    stroke="#00898D"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        accept="image/png, image/jpeg"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    <button className="py-2 save-btn" onClick={handleSave}>Save</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default ProjectListing;
