import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./start-growing.css";
import GrowMasterMain from "../../components/grow-master";
import GrowLog from "../../components/grow-log";
import GrowTracking from "../../components/grow-tracking";
import UserMap from "../../components/GoogleMap";
import GrowDoc from "../../components/growDoc";

function StartGrowing() {
  const [activeTab, setActiveTab] = useState(null);

  return (
    <div className="start-growing">
      <div className="hero d-flex justify-content-center align-items-center">
        <Container className="d-flex justify-content-center align-items-center">
          <div className="hero-content d-flex flex-column justify-content-center align-items-center">
            <h1 className="from-seeds-to">
              <span className="mx-3">From </span>{" "}
              <span className="text-theme"> seeds </span>{" "}
              <span className="mx-3">to</span> <br />
            </h1>
            <span className="AmeyallindaFont text-white">serenity</span>
          </div>
        </Container>
      </div>

      {!activeTab && (
        <div className="amazing-features-section">
          <Container fluid>
            <Row>
              <Col
                lg={4}
                className="grow-log"
                onClick={() => setActiveTab("log")}
              >
                <div
                  className="feature position-relative"
                  style={{
                    backgroundImage: `url("./images/features/feature-1.png")`,
                  }}
                >
                  <div className="feature-text">
                    <p className="text-white">
                      Grow <span>Log</span>
                    </p>
                  </div>
                </div>
                <div className="feature-footer-text">
                  <p className="text-white">
                    Elevate your <br /> gardening process <br /> with GrowLog
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                className="grow-master"
                onClick={() => setActiveTab("master")}
              >
                <div
                  className="feature position-relative"
                  style={{
                    backgroundImage: `url("./images/features/feature-2.png")`,
                  }}
                >
                  <div className="feature-text">
                    <p className="text-white">
                      Grow <span>Master</span>
                    </p>
                  </div>
                </div>
                <div className="feature-footer-text">
                  <p className="text-white">
                    Unlock your plant's full <br /> potential with GrowMaster's{" "}
                    <br />
                    personalized support for <br /> premium subscribers.
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                className="grow-tracking"
                onClick={() => setActiveTab("tracking")}
              >
                <div
                  className="feature position-relative"
                  style={{
                    backgroundImage: `url("./images/features/feature-3.png")`,
                  }}
                >
                  <div className="feature-text">
                    <p className="text-white">
                      Grow <span>Tracking</span>
                    </p>
                  </div>
                </div>
                <div className="feature-footer-text">
                  <p className="text-white">
                    Elevate plant care <br /> with Grow Tracking: <br />{" "}
                    Monitor, optimize, <br /> Thrive
                  </p>
                </div>
              </Col>
              <Col
                lg={4}
                className="grow-tracking"
                onClick={() => setActiveTab("GrowDoc")}
              >
                <div
                  className="feature position-relative"
                  style={{
                    backgroundImage: `url("./images/features/feature-3.png")`,
                  }}
                >
                  <div className="feature-text">
                    <p className="text-white">
                      Grow <span>Doc</span>
                    </p>
                  </div>
                </div>
                <div className="feature-footer-text">
                  <p className="text-white">
                   Grow Doc to indetify  <br />  the cause of sick cannabis<br />plants
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {activeTab === "log" && <GrowLog />}
      {activeTab === "master" && <GrowMasterMain />}
      {activeTab === "tracking" && <GrowTracking />}
      {activeTab === "GrowDoc" && <GrowDoc />}

    </div>
  );
}

export default StartGrowing;
