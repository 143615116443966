import React, { useEffect, useState } from "react";
import Plan from "./Plan";
import "./pricing.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Pricing() {
  const [isLoading, setLoading] = useState(true);
  const [plansData, setPlansData] = useState([]);
  const [plansDataApi, setPlansDataApi] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const growerUser = JSON.parse(localStorage.getItem("grower_user"));
      const token = growerUser?.token;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/get-user`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.setItem("grower_login", JSON.stringify(response.data));
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/growx/subscriptions`)
      .then((response) => {
        setPlansDataApi(response.data);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const defaultPlansData = [
      {
        id: 1,
        name: "Basic",
        type: "Free (Lifetime)",
        price: 0,
        duration: "",
        currency: "USD",
        desc: "",
        buttonText: "Get Started",
        isActive: false,
        paypal_id: null,
        whatsIncluded: [
          { label: "All Videos", value: true },
          { label: "Grow Log", value: true },
          { label: "Technician On Demand", value: false },
          { label: "Send Emails", value: false },
          { label: "Live Chat", value: false },
          { label: "Video Consultancy (4 Calls)", value: false },
        ],
      },
      {
        id: 2,
        name: "Standard",
        type: "$199/Year",
        price: 199,
        duration: "Year",
        currency: "USD",
        desc: "",
        buttonText: "Buy Now",
        isActive: false,
        paypal_id: null,
        whatsIncluded: [
          { label: "All Videos", value: true },
          { label: "Grow Log", value: true },
          { label: "Technician On Demand", value: true },
          { label: "Send Emails", value: false },
          { label: "Live Chat", value: false },
          { label: "Video Consultancy (4 Calls)", value: false },
        ],
      },
      {
        id: 3,
        name: "Premium",
        type: "$299/Year",
        price: 299,
        duration: "Year",
        currency: "USD",
        desc: "",
        buttonText: "Buy Now",
        isActive: false,
        paypal_id: null,
        whatsIncluded: [
          { label: "All Videos", value: true },
          { label: "Grow Log", value: true },
          { label: "Technician On Demand", value: true },
          { label: "Send Emails", value: true },
          { label: "Live Chat", value: true },
          { label: "Video Consultancy (4 Calls)", value: true },
        ],
      },
    ];

    const userData = JSON.parse(localStorage.getItem("grower_login"));
    const currentSubscriptionId = userData?.data.current_subscription?.id;

    const updatedPlansDataWithPaypal = defaultPlansData.map((plan) => {
      const isActive = currentSubscriptionId
        ? plan.id === currentSubscriptionId
        : plan.id === 1;
      const buttonText =
        isActive && (plan.id === 2 || plan.id === 3)
          ? "Cancel Now"
          : plan.buttonText;
      return {
        ...plan,
        paypal_id:
          plansDataApi.data?.find((p) => p.id === plan.id)?.paypal_id || null,
        isActive: isActive,
        buttonText: buttonText,
      };
    });

    setPlansData(updatedPlansDataWithPaypal);
    setLoading(false);
  }, [plansDataApi, userData]);

  // Handle plan cancellation
  const handleCancelSubscription = async (planId) => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/growx/cancel-subscription`,
        { reason: "Cancel it now" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Subscription cancelled successfully!");
        setPlansData((prevPlans) =>
          prevPlans.map((plan) =>
            plan.id === planId
              ? { ...plan, isActive: false, buttonText: "Buy Now" }
              : plan
          )
        );
        // Re-fetch the user data after cancellation
        setLoading(true);
        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
        const token = growerUser?.token;
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/get-user`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          localStorage.setItem("grower_login", JSON.stringify(response.data));
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      toast.error("Failed to cancel subscription. Please try again.");
    }
  };

  // Handle plan selection
  const handlePlanClick = (plan) => {
    if (plan.id === 1 && plan.isActive) {
      toast.info("Already You are using the free Basic plan");
    } else {
      setSelectedPlan(plan);
      setShowModal(true);
    }
  };

  // Handle payment success
  const handlePaymentSuccess = async (transactionData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/create-transaction`,
        transactionData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Subscription purchased and updated successfully!");
      setShowModal(false);
      // Re-fetch the user data after payment
      setLoading(true);
      const growerUser = JSON.parse(localStorage.getItem("grower_user"));
      const token = growerUser?.token;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/get-user`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        localStorage.setItem("grower_login", JSON.stringify(response.data));
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update subscription. Please contact support.");
    }
  };

  return (
    <div className="pricing-page container">
      <ToastContainer />
      <div className="text-center">
        <h1 className="heading">Our pricing plans</h1>
        <p>
          Choose the plan that’s tailored to your needs and start experiencing
          the full benefits today. Whether you're just getting started or
          looking to upgrade, we have options designed for every level. Select a
          plan to unlock exclusive features and take your experience to the next
          level!
        </p>
      </div>
      {isLoading ? (
        <div className="text-center my-5 py-5 text-dark">loading...</div>
      ) : (
        <div className="plans mb-5 mt-5">
          {plansData && plansData.length ? (
            <div className="row">
              {plansData.map((plan, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <Plan
                    plan={plan}
                    onPlanClick={handlePlanClick}
                    onCancel={() => handleCancelSubscription(plan.id)}
                  />
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {showModal && selectedPlan && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0 d-flex justify-content-between">
                <h5 className="modal-title" id="exampleModalLabel">
                  PayPal Payment
                </h5>
                <button
                  type="button"
                  className="close px-2 bg-transparent border-0"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                >
                  <span className="fs-1" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <p className="mb-3">
                  Please complete your payment for the {selectedPlan.name} plan
                  priced at ${selectedPlan.price}:
                </p>
                <PayPalScriptProvider
                  options={{
                    "client-id":
                      "Ae_O_6KyPA1XW5SqEzRoxigr5p1b_wLo8r0MkKwfYiFKAsrUKdfKy9ozzc4GenlajKtgNtZS0dKc08Iu",
                    vault: true,
                  }}
                >
                  <PayPalButtons
                    createSubscription={(data, actions) => {
                      if (!selectedPlan?.paypal_id) {
                        toast.error(
                          "PayPal ID for the selected plan is missing."
                        );
                        return;
                      }
                    
                      return actions.subscription.create({
                        plan_id: selectedPlan.paypal_id,
                      });
                    }}
                    onApprove={async (data, actions) => {
                      try {
                        const growerUser = JSON.parse(localStorage.getItem("grower_user"));
                        const userEmail = growerUser?.user.email;
                        const subscriptionDetails =
                          await actions.subscription.get();
                        const transactionData = {
                          subscription_id: data.subscriptionID,
                          ba_token: "BA-8RB96458XY7941606",
                          token: data.facilitatorAccessToken || "",
                          additional_info: {
                            // email: subscriptionDetails.subscriber.email_address,
                             email: userEmail, 
                            user_note: "Please subscribe me",
                            product_id: selectedPlan.id,
                            app: "GROWX",
                            created_via: "Web",
                          },
                        };

                        handlePaymentSuccess(transactionData);
                      } catch (error) {
                        toast.error(
                          "Payment successful, but we could not retrieve subscription details. Please contact support."
                        );
                      }
                    }}
                  />
                </PayPalScriptProvider>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pricing;
