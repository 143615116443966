import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Select from "react-select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const MeetingDate = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    const fetchProjects = async () => {
      const growerUser = JSON.parse(localStorage.getItem("grower_user"));
      const token = growerUser?.token;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/growx/projects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Access the nested data array
        const projectsData = response.data.data?.data;
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          console.error("Unexpected response format:", response.data);
          toast.error("Failed to load projects.");
        }
      } catch (err) {
        console.error("Error fetching projects:", err);
        toast.error("Error fetching projects.");
      }
    };

    fetchProjects();
  }, []);


  const projectOptions = Array.isArray(projects)
    ? projects.map((project) => ({
      value: project.id,
      label: project.project_title,
    }))
    : [];


  const handleProjectChange = (selectedOption) => {
    setSelectedProject(selectedOption);
  };


  return (

    <>
      <div className="start-growing bg-transparent">
        <h5>Pick a date </h5>
        <Calendar onChange={onChange} value={value} />

        <div className="row py-4">
          <div className="col-12 col-lg-6 mx-auto">
            <label htmlFor="project" className="mb-2 fs-5 fw-500">
              Pick a time
            </label>
            <input className='form-control' type='time' />
          </div>
          <div className="col-12 col-lg-6 mx-auto">
            <label htmlFor="project" className="mb-2 fs-5 fw-500">
              Please Select Project
            </label>
            <Select
              options={projectOptions}
              onChange={handleProjectChange}
              value={selectedProject}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2 pb-4">
          <button className="py-2 px-5 proceed-btn">Proceed</button>
        </div>
      </div>
    </>
  )
}

export default MeetingDate;
