

// import React, { useEffect, useState } from "react";
// import { GoogleMap, Marker, InfoWindow, Polyline, useJsApiLoader } from "@react-google-maps/api";
// import { FaUserTie } from "react-icons/fa";

// const GOOGLE_MAPS_API_KEY = "AIzaSyALtPY2K3RllnOU4sMd_iTH6cLsKX1mEyA";

// const UserMap = () => {
//   const [users, setUsers] = useState([]);
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [directions, setDirections] = useState(null);

//   const { isLoaded } = useJsApiLoader({
//     googleMapsApiKey: GOOGLE_MAPS_API_KEY,
//     libraries: ["maps", "places", "directions"],
//   });

//   const fetchUsers = async () => {
//     const growerUser = JSON.parse(localStorage.getItem("grower_user"));
//     const token = growerUser?.token;

//     try {
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/growx/users?role=grow_master`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       const data = await response.json();
//       if (data.status) {
//         setUsers(data.data);
//       } else {
//         console.error("Failed to fetch users:", data.message);
//       }
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     }
//   };

//   useEffect(() => {
//     fetchUsers();
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setCurrentLocation({ lat: latitude, lng: longitude });
//       },
//       (error) => {
//         console.error("Geolocation error:", error);
//       }
//     );
//   }, []);

//   useEffect(() => {
//     if (currentLocation && selectedUser) {
//       const directionsService = new window.google.maps.DirectionsService();
//       const directionsRenderer = new window.google.maps.DirectionsRenderer();

//       directionsRenderer.setMap(window.google.maps.Map);

//       const request = {
//         origin: currentLocation,
//         destination: {
//           lat: parseFloat(selectedUser.location.latitude),
//           lng: parseFloat(selectedUser.location.longitude),
//         },
//         travelMode: window.google.maps.TravelMode.DRIVING,
//       };

//       directionsService.route(request, (result, status) => {
//         if (status === window.google.maps.DirectionsStatus.OK) {
//           setDirections(result.routes[0].overview_path);
//         } else {
//           console.error("Directions request failed:", status);
//         }
//       });
//     }
//   }, [currentLocation, selectedUser]);

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <GoogleMap
//       mapContainerStyle={{ width: "100%", height: "500px" }}
//       center={currentLocation || { lat: 37.421998, lng: -122.084 }}
//       zoom={currentLocation ? 5 : 5}
//     >
//       {currentLocation && (
//         <Marker
//           position={currentLocation}
//           title="Your Location"
//           icon={{
//             url: "/images/pickup.png",
//             scaledSize: new window.google.maps.Size(40, 40),
//           }}
//         />
//       )}

//       {users
//         .filter(
//           (user) =>
//             user.location &&
//             user.location.latitude !== null &&
//             user.location.longitude !== null
//         )
//         .map((user) => (
//           <Marker
//             key={user.id}
//             position={{
//               lat: parseFloat(user.location.latitude),
//               lng: parseFloat(user.location.longitude),
//             }}
//             icon={{
//               url: "/images/userLocationicon.png",
//               scaledSize: new window.google.maps.Size(30, 40),
//             }}
//             title={user.name || user.email}
//             onClick={() => setSelectedUser(user)}
//           />
//         ))}

//       {selectedUser && (
//         <InfoWindow
//           position={{
//             lat: parseFloat(selectedUser.location.latitude),
//             lng: parseFloat(selectedUser.location.longitude),
//           }}
//           onCloseClick={() => setSelectedUser(null)}
//         >
//           <div className="infoBoxMap">
//             <div>
//               <div className="mb-2">
//                 <FaUserTie className="fs-1" style={{ color: "#00898d" }} />
//               </div>
//               <h5 className="text-uppercase mb-0">{selectedUser.name || "No Name"}</h5>
//               <p className="mb-2 fw-600">{selectedUser.role}</p>
//               <p><strong>Email:</strong> {selectedUser.email}</p>

//               <div className="d-flex gap-3">
//                 <p><strong>Phone:</strong> {selectedUser.phone || "Not Available"}</p>
//                 <p><strong>Arrival Time:</strong> {selectedUser.phone || "Not Available"}</p>
//               </div>
//             </div>
//           </div>
//         </InfoWindow>
//       )}

//       {directions && (
//         <Polyline
//           path={directions}
//           options={{
//             strokeColor: "#00898D",
//             strokeOpacity: 1.0,
//             strokeWeight: 5,
//           }}
//         />
//       )}
//     </GoogleMap>
//   );
// };

// export default UserMap;



import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { FaUserTie } from "react-icons/fa";

const GOOGLE_MAPS_API_KEY = "AIzaSyALtPY2K3RllnOU4sMd_iTH6cLsKX1mEyA";

const UserMap = ({ onProceed }) => {
  const [users, setUsers] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [directions, setDirections] = useState(null);
  const [aerialPath, setAerialPath] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["maps", "places", "directions"],
  });

  const fetchUsers = async () => {
    const growerUser = JSON.parse(localStorage.getItem("grower_user"));
    const token = growerUser?.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/growx/users?role=grow_master`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status) {
        setUsers(data.data);
      } else {
        console.error("Failed to fetch users:", data.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Geolocation error:", error);
      }
    );
  }, []);

  useEffect(() => {
    if (currentLocation && selectedUser) {
      const userLocation = {
        lat: parseFloat(selectedUser.location.latitude),
        lng: parseFloat(selectedUser.location.longitude),
      };

      // Calculate distance
      const distance = haversineDistance(currentLocation, userLocation);

      if (distance > 1000) {
        // Show straight-line route for far distances
        setAerialPath([currentLocation, userLocation]);
        setDirections(null);
      } else {
        // Use Directions API for shorter distances
        const directionsService = new window.google.maps.DirectionsService();
        const request = {
          origin: currentLocation,
          destination: userLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result.routes[0].overview_path);
            setAerialPath(null);
          } else {
            console.error("Directions request failed:", status);
          }
        });
      }
    }
  }, [currentLocation, selectedUser]);

  // Haversine formula to calculate great-circle distance between two points
  const haversineDistance = (coord1, coord2) => {
    const R = 6371; // Earth's radius in km
    const toRad = (value) => (value * Math.PI) / 180;

    const dLat = toRad(coord2.lat - coord1.lat);
    const dLng = toRad(coord2.lng - coord1.lng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coord1.lat)) * Math.cos(toRad(coord2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "500px" }}
      center={currentLocation || { lat: 37.421998, lng: -122.084 }}
      zoom={currentLocation ? 6 : 5}
    >
      {/* Current Location Marker */}
      {currentLocation && (
        <Marker
          position={currentLocation}
          title="Your Location"
          icon={{
            url: "/images/pickup.png",
            scaledSize: new window.google.maps.Size(40, 40),
          }}
        />
      )}

      {/* User Markers */}
      {users
        .filter(
          (user) =>
            user.location &&
            user.location.latitude !== null &&
            user.location.longitude !== null
        )
        .map((user) => (
          <Marker
            key={user.id}
            position={{
              lat: parseFloat(user.location.latitude),
              lng: parseFloat(user.location.longitude),
            }}
            icon={{
              url: "/images/userLocationicon.png",
              scaledSize: new window.google.maps.Size(30, 40),
            }}
            title={user.name || user.email}
            onClick={() => setSelectedUser(user)}
          />
        ))}

      {/* Info Window */}
      {selectedUser && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedUser.location.latitude),
            lng: parseFloat(selectedUser.location.longitude),
          }}
          onCloseClick={() => setSelectedUser(null)}
        >
          <div className="infoBoxMap">
            <div>
              <div className="mb-2">
                <FaUserTie className="fs-1" style={{ color: "#00898d" }} />
              </div>
              <h5 className="text-uppercase mb-0">{selectedUser.name || "No Name"}</h5>
              <p className="mb-2 fw-600">{selectedUser.role}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <div className="d-flex gap-3">
                <p><strong>Phone:</strong> {selectedUser.phone || "Not Available"}</p>
                <p><strong>Arrival Time:</strong> {selectedUser.phone || "Not Available"}</p>
              </div>
              <div className="d-flex justify-content-center pt-2">
            <button onClick={() => onProceed(selectedUser)} className="py-2 px-5 proceed-btn">Proceed</button>
            </div>
            </div>
          </div>
        </InfoWindow>
      )}
      {directions && (
        <Polyline
          path={directions}
          options={{
            strokeColor: "#00898D",
            strokeOpacity: 1.0,
            strokeWeight: 5,
          }}
        />
      )}

      {aerialPath && (
        <Polyline
          path={aerialPath}
          options={{
            strokeColor: "#0088FF",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            strokeDasharray: [10, 10],
          }}
        />
      )}
    </GoogleMap>
  );
};

export default UserMap;
