import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import RegisterForm from "../../pages/register/RegisterForm";
import "./footer.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";

function Footer() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const handleClose = () => setRegisterModalShow(false);
  const handleShow = () => setRegisterModalShow(true);

  useEffect(() => {
    const user = localStorage.getItem("grower_user");
    setIsLoggedIn(!!user);
  }, [location]);

  const download = () => {
    if (isAndroid) {
      window.open(process.env.REACT_APP_ANDROID_APP);
    } else if (isIOS) {
      window.open(process.env.REACT_APP_IOS_APP);
    } else {
      window.open(process.env.REACT_APP_ANDROID_APP);
    }
  };

  return (
    <div>
      <footer className="text-center text-lg-start">
        <section className="">
          <div className="container text-center text-md-start pt-md-5 pt-4 pb-3">
            <div className="row mt-3">
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Company</h5>
                <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    Home
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/tutorials"} className="text-reset">
                    Tutorials
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/pricing"} className="text-reset">
                    Pricing
                  </Link>
                </p>
                <p className="footer-link">
                  <Link onClick={handleShow} className="text-reset">
                    Registration
                  </Link>
                </p>
                <p className="footer-link">
                  <Link onClick={download} className="text-reset">
                    Download App
                  </Link>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Get Help</h5>
                <p className="footer-link">
                  <Link to={"/faq"} className="text-reset">
                    FAQ
                  </Link>
                </p>

                <p className="footer-link">
                  <Link to={"/contact-us"} className="text-reset">
                    Contact
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/pricing"} className="text-reset">
                    Subscriptions
                  </Link>
                </p>
                {isLoggedIn && (
                  <p className="footer-link">
                    <Link to={"/projects"} className="text-reset">
                      My Projects
                    </Link>
                  </p>
                )}
                {isLoggedIn && (
                  <p className="footer-link">
                    <Link to={"/notifications"} className="text-reset">
                      Notifications
                    </Link>
                  </p>
                )}
                <p className="footer-link">
                  <Link to={"/privacy-policy"} className="text-reset">
                    Privacy Policy
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/terms-conditions"} className="text-reset">
                    Terms & Conditions
                  </Link>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Our Family</h5>
                <p className="footer-link">
                  <Link
                    to={"https://www.homegrownpros.io/"}
                    target="_blank"
                    className="text-reset"
                  >
                    HGP
                  </Link>
                </p>
                <p className="footer-link">
                  <Link
                    to={"https://clone-z.vercel.app/"}
                    target="_blank"
                    className="text-reset"
                  >
                    CloneX A.i
                  </Link>
                </p>
                <p className="footer-link">
                  <Link
                    to={"https://hgxtv.com/"}
                    target="_blank"
                    className="text-reset"
                  >
                    HgxTV
                  </Link>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Follow us</h5>
                <div className="social-platforms">
                  <a
                    className="facebook"
                    href="https://www.facebook.com/growxai/"
                    target="_blank"
                  >
                    <img src="./images/facebook.svg" alt="facebook" />
                  </a>
                  <a
                    className="instagram"
                    href="https://www.instagram.com/growxai?igsh=N3g3bTlybzhzaWU0&utm_source=qr"
                    target="_blank"
                  >
                    <img src="./images/instagram.svg" alt="instagram" />
                  </a>
                  <a
                    className="youtube"
                    target="_blank"
                    href="https://youtube.com/@hgxtv_?feature=shared"
                  >
                    <img src="./images/youtube.svg" alt="instagram" />
                  </a>
                  <a
                    className="linkedin"
                    target="_blank"
                    href="https://www.linkedin.com/in/jerry-%E2%80%9Cjayd%E2%80%9D-dababneh-9b20191b0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                  >
                    <img src="./images/linkedin.svg" alt="instagram" />
                  </a>
                </div>
                <img
                  src="./images/logo.png"
                  alt="GrowX"
                  width="195px"
                  height="54px"
                />
              </div>
            </div>
          </div>
          <div className="text-center pb-4">
            <p className="mb-0 rights-reserved">
              @2024 Powered by: GrowX A.i Technologies LLC. All Rights
              Reserved. 
            </p>
          </div>
        </section>
      </footer>
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <RegisterForm />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Footer;
